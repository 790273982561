import { PageParagraphs } from "../PageParagraphs";
import { PageSectionHeading } from "../PageSectionHeading";
import { PageSectionParagraph } from "../PageSectionParagraph";
import { PageSectionTagline } from "../PageSectionTagline";
import React, { ReactNode } from "react";

const PageSectionContent = ({
  children,
  tag,
  heading,
  content,
  className,
  tagClass = "text-gogreen",
  headingClass,
  contentClass,
}: {
  children?: ReactNode;
  tag?: string;
  heading?: string;
  content?: string;
  className?: string;
  tagClass?: string;
  headingClass?: string;
  contentClass?: string;
}) => (
  <div className={className}>
    <PageParagraphs gap="gap-6">
      {tag && (
        <PageSectionTagline className={tagClass}>{tag}</PageSectionTagline>
      )}
      {heading && (
        <PageSectionHeading className={headingClass}>
          {heading}
        </PageSectionHeading>
      )}
      {content && (
        <PageSectionParagraph className={contentClass}>
          {content}
        </PageSectionParagraph>
      )}
      {children}
    </PageParagraphs>
  </div>
);

export { PageSectionContent };
