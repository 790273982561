import { PageContainer } from "../components/PageContainer";
import { PageLayout } from "../components/PageLayout";
import { PageSection } from "../components/PageSection";
import { PageSectionContent } from "../components/PageSectionContent";
import { SiteButton } from "../components/SiteButton";
import { SiteSEO } from "../components/SiteSEO";
import React from "react";

const IndexPage = () => {
  return (
    <PageLayout>
      <SiteSEO title="Page Not Found" />
      <PageContainer>
        <PageSection>
          <PageSectionContent
            className="py-24"
            heading="Page Not Found"
            content="Sorry, the page you're looking for was not found."
          >
            <SiteButton to="/">Go Home</SiteButton>
          </PageSectionContent>
        </PageSection>
      </PageContainer>
    </PageLayout>
  );
};

export default IndexPage;
