import React, { ReactNode } from "react";

const PageSectionTagline = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={`font-bold uppercase ${className}`}>{children}</div>;

export { PageSectionTagline };
