import React, { ReactNode } from "react";

const PageSectionParagraph = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={`text-lg leading-7 ${className}`}>{children}</div>;

export { PageSectionParagraph };
