import React, { ReactNode } from "react";

const PageSectionHeading = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={`text-4xl font-black ${className}`}>{children}</div>;

export { PageSectionHeading };
